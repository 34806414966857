import { B2Hosts, OriginalB2Host } from "./constants"
export function getWebUrls(bucket, objectKey) {
    const webURLs = B2Hosts.map(host => getWebUrl(host, bucket, objectKey));
    return webURLs;
}

export function getWebUrl(host, bucket, objectKey) {
    return `${host}${bucket}/${encodeURIComponent(objectKey)}`.replace("%2F", "/");
}

export function getOriginalWebUrl(bucket, objectKey) {
    return getWebUrl(OriginalB2Host, bucket, objectKey);
}