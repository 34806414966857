import supportGetSaveKeys from "./supportGetSaveKeys";
import hasSaveFallback from "./hasSaveFallback";

export default async function (key) {
  if (supportGetSaveKeys) {
    const dbs = await window.indexedDB.databases();
    return dbs.some((db) => db.name === key);
  } else {
    return await hasSaveFallback(key);
  }
}
