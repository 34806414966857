import isChina from "@/utils/isChina";

const china_prefix = `/static/em-dosbox/`;
const jsdelivr_prefix = `/static/em-dosbox/`;

// em-dosbox URL
export const dosbox_js_url = isChina
  ? `${china_prefix}dosbox-sync.js`
  : `${jsdelivr_prefix}dosbox-sync.js`;
export const dosbox_mem_url = isChina
  ? `${china_prefix}dosbox-sync.mem`
  : `${jsdelivr_prefix}dosbox-sync.mem`;

export const emularity_games = ["麻雀幻想曲3"]