import { getOriginalWebUrl } from "./getWebUrls";
import { getLogger } from "@/utils/log";

const cacheKey = "ObjectStorage";
const logger = getLogger(["objectStorage", "cache"])

// return blob | undefined
export async function getObjectBlobFromCache(bucket, objectKey) {
  try {
    const url = getOriginalWebUrl(bucket, objectKey);
    const cache = await window.caches.open(cacheKey);
    const response = await cache.match(url);
    let blobCache = undefined;
    if (response) {
      logger.log(`Load from cache: ${url}`);
      blobCache = await response.blob();
      return blobCache;
    } else {
      throw new Error(`Cache miss: ${url}`);
    }
  } catch (e) {
    logger.warn(`Failed to find ${bucket} ${objectKey} in cache`);
    return undefined;
  }
}

export async function putObjectBlobToCache(bucket, objectKey, blob) {
  try {
    const url = getOriginalWebUrl(bucket, objectKey);
    const cache = await window.caches.open(cacheKey);
    cache.put(
      new Request(url),
      new Response(blob, {
        status: 200,
        statusText: "OK",
        headers: { "Content-Length": blob.size },
      })
    );
    logger.log(`Put ${bucket} ${objectKey} blob into cache`);
  } catch (e) {
    logger.error(`Failed to put ${bucket} ${objectKey} blob in cache: ${e}`);
    return;
  }
}
