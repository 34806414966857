import { getBlobUsingWebTorrent } from "./webTorrent/getBlobUsingWebTorrent";
import { getOriginalWebUrl } from "./getWebUrls";

import { getLogger } from "@/utils/log";

const logger = getLogger(["objectStorage"]);

export async function getBlob(bucket, objectKey, config, callback_) {
  let { seed, cache, webTorrent } = config;
  logger.log(`Bucket: ${bucket}`);
  logger.log(`ObjectKey: ${objectKey}`);
  logger.log(`WebTorrent: ${webTorrent}`);
  logger.log(`Seed: ${seed}, Cache: ${cache}`);
  try {
    if (webTorrent) {
      logger.log(`Get Blob Using WebTorrent`);
      const webTorrentPromise = getBlobUsingWebTorrent(
        bucket,
        objectKey,
        seed,
        cache,
        callback_
      );
      const fetchPromise = getBlobUsingFetch(bucket, objectKey);
      const blob = await Promise.any([webTorrentPromise, fetchPromise]);
      return blob;
    } else {
      logger.log(`Get Blob Using Fetch`);
      return await getBlobUsingFetch(bucket, objectKey);
    }
  } catch {
    logger.log(`Error, Fallback to Fetch`);
    return await getBlobUsingFetch(bucket, objectKey);
  }
}

export async function getBlobUsingFetch(bucket, objectKey) {
  const url = getOriginalWebUrl(bucket, objectKey);
  const resp = await fetch(url);
  return await resp.blob();
}
