import WebTorrent from "webtorrent";
import filesize from "filesize";

import { getTorrentBlob } from "./getTorrentBlob";
import { getWebUrls } from "../getWebUrls";
import { getObjectBlobFromCache, putObjectBlobToCache } from "../objectCache";
import { statusType } from "../statusType";

import { getLogger } from "@/utils/log";

const logger = getLogger(["objectStorage", "webTorrent"]);

export async function getBlobUsingWebTorrent(
  bucket,
  objectKey,
  seed,
  cache,
  callback_
) {
  const wrapperPromise = new Promise((resolve, reject) => {
    getBlobUsingWebTorrent_(bucket, objectKey, seed, cache, callback_, reject)
      .then((blob) => resolve(blob))
      .catch((err) => reject(err));
  });
  return await wrapperPromise;
}

async function getBlobUsingWebTorrent_(
  bucket,
  objectKey,
  seed,
  cache,
  callback_,
  reject
) {
  const callback = callback_ || function () {};

  callback({
    currentLength: 1,
    totalLength: 1,
    downloadSpeed: 0,
    status: statusType.loadingMetadata,
  });

  if (cache) {
    const blobCache = await getObjectBlobFromCache(bucket, objectKey);
    if (blobCache) {
      logger.log("Get Binary Cache, Skip WebTorrent");
      return blobCache;
    }
  }

  logger.log(`Creating WebTorrent Client`);
  const client = new WebTorrent();
  client.on("error", function (err) {
    logger.error("WebTorrent Client Error");
    callback({
      currentLength: 1,
      totalLength: 1,
      downloadSpeed: 0,
      status: statusType.error,
    });
    logger.log("Destory WebTorrent Client");
    client.destroy();
    reject(err);
    throw err;
  });

  logger.log(`Fetching Torrent..`);
  const torrentBlob = await getTorrentBlob(bucket, objectKey);
  logger.log(`Torrent Fetched. Torrent Size: ${filesize(torrentBlob.size)}`);

  // Get Torrent
  const torrent = await new Promise((resolve) => {
    client.add(torrentBlob, { maxWebConns: 10 }, (torrent) => {
      resolve(torrent);
    });
  });

  torrent.on('wire', function (wire) {
    logger.log(`Peer Connected. Type: ${wire.type}`);
  })

  logger.log(`Torrent Metadata Loaded`);
  logger.log(`Torrent InfoHash: ${torrent.infoHash}`)
  logger.log(`Torrent Piece Length: ${filesize(torrent.pieceLength, {base: 2})}`)
  logger.log(`Torrent Max Web Connections: ${torrent.maxWebConns}`)

  torrent.on("error", (err) => {
    logger.error("Download torrent Error");
    console.error(err);
    callback({
      currentLength: 1,
      totalLength: 1,
      downloadSpeed: 0,
      status: statusType.error,
    });
    logger.log("Destory WebTorrent Client");
    client.destroy();
    reject(err);
    throw err;
  });

  const file = torrent.files[0];

  const execCallback = () => {
    let status = torrent.done? statusType.success :statusType.downloading;

    callback({
      currentLength: file.downloaded,
      totalLength: file.length,
      downloadSpeed: torrent.downloadSpeed,
      status
    });
  }

  // done Promise
  const donePromise = new Promise((resolve) => {
    torrent.on("done", () => {
      resolve();
      execCallback();
    });
  });

  torrent.on('download', execCallback)

  const interval = setInterval(execCallback, 200);

  // Add web seed
  const webUrls = getWebUrls(bucket, objectKey);
  logger.log(`Adding ${webUrls.length} WebSeeds`);
  webUrls.forEach((webUrl) => {
    torrent.addWebSeed(webUrl);
  });

  const blobPromise = new Promise((resolve, reject) => {
    file.getBlob((err, blob) => {
      if (err) {
        reject(err);
      } else {
        resolve(blob);
      }
    });
  });

  const blob = await blobPromise;

  await donePromise;
  logger.log("Torrent Downloaded Compeleted.");
  logger.log(`Torrent Downloaded Size: ${filesize(file.length)}`);
  clearInterval(interval);
  logger.log(`Callback Interval Cleared`);

  const explictSeed = seed === true;
  const underThreshold = typeof seed == "number" && blob.size < seed;
  const seed_ = seed === true || underThreshold;

  if (underThreshold) {
    logger.log("Torrent Size Under Threshold, Keep Seeding 🌱");
  }

  if (explictSeed) {
    logger.log("Set Seeding Explictly, Keep Seeding 🌱");
  }

  logger.log(`Number of peers: ${torrent.numPeers}`);

  if (!seed_) {
    logger.log("Destory WebTorrent Client");
    client.destroy();
  }

  if (cache) {
    putObjectBlobToCache(bucket, objectKey, blob);
  }

  return blob;
}
