import { getWebUrl } from "../getWebUrls";

export async function getLatency(host) {
  const url = getWebUrl(host, "dos-bin", "test.json");

  const start = Date.now();
  const res = await fetch(url, { cache: "no-cache" });
  const json = await res.json();

  if (json.success) {
    const latency = Date.now() - start;
    return latency;
  } else {
    throw new Error(`Failed to fetch ${host}: Not success json`);
  }
}
