import { B2Hosts } from "../constants"

export function getTorrentUrl(bucket, objectKey) {
  return getTorrentUrl_(torrentPrefixs[0], bucket, objectKey);
}

function getTorrentUrl_(prefix, bucket, objectKey) {
  return `${prefix}${bucket}/${encodeURIComponent(objectKey)}.torrent`.replace("%2F", "/");
}

const torrentPrefixs = B2Hosts.map(prefix => prefix + "torrentize/");

export function getTorrentUrls(bucket, objectKey) {
    const torrentURLs = torrentPrefixs.map(prefix => getTorrentUrl_(prefix, bucket, objectKey));
    return torrentURLs;
}


