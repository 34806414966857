import gameDataJson from "@/utils/info/gameDataJson";
import supportGetSaveKeys from "./supportGetSaveKeys";

const game_keys = Object.keys(gameDataJson);

export default async function () {
  if (supportGetSaveKeys) {
    const dbs = await window.indexedDB.databases();
    const game_save_dbs = dbs.filter((db) => game_keys.includes(db.name));
    return game_save_dbs.map((db) => db.name);
  } else {
    return [];
  }
}
