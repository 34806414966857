import { getTorrentUrls, getTorrentUrl } from "./getTorrentUrl";

export async function getTorrentBlob(bucket, objectKey) {
  try {
    const torrentURLs = getTorrentUrls(bucket, objectKey);
    const promises = torrentURLs.map((url) => fetch(url));
    const successResponse = await Promise.any(promises);
    const blob = await successResponse.blob();
    if (blob) {
      return blob;
    } else {
      throw new Error("Torrent Blob is empty");
    }
  } catch {
    const torrentUrl = getTorrentUrl(bucket, objectKey);
    const torrentResponse = await fetch(torrentUrl);
    const torrentBuffer = await torrentResponse.blob();
    if (torrentBuffer) {
      return torrentBuffer;
    } else {
      throw new Error("Failed to fetch torrent blob");
    }
  }
}
