export default function (save_key) {
  return new Promise((resolve) => {
    var request = window.indexedDB.open(save_key);
    request.onupgradeneeded = function (event) {
      event.target.transaction.abort();
      resolve(false);
    };
    request.onsuccess = function () {
      resolve(true);
    };
    request.onerror = function () {
      resolve(false);
    };
  });
}
