import { dosbox_js_url, dosbox_mem_url } from "./constants";
import { getLogger } from "@/utils/log";

export default async (dom, options) => {
  const logger = getLogger(["backend", "emularity"]);
  logger.log(`Binary URL: ${options.binary_url}`);
  logger.log(`Drive type: ${options.drive_type}`);
  logger.log(`Save Key: ${options.save_key}`);
  logger.log(`Executable: ${options.executable}`);
  logger.log(`Img: ${options.img}`);

  const emularity = await import(
    /* webpackChunkName: "emularity" */ "@/vendors/emularity/index.js"
  );
  const { DosBoxLoader, Emulator } = emularity;

  const locateAdditionalFiles = () => dosbox_mem_url;

  const dosbox_args = [
    DosBoxLoader.emulatorJS(dosbox_js_url),
    DosBoxLoader.locateAdditionalEmulatorJS(locateAdditionalFiles),
    DosBoxLoader.nativeResolution(640, 480),
    DosBoxLoader.fileSystemKey(options.save_key),
    DosBoxLoader.mountZip(
      "c",
      DosBoxLoader.fetchFile("Game File", options.binary_url),
      options.drive_type
    ),
    DosBoxLoader.startExe(options.debug ? "" : options.executable),
  ];

  if (options.img) {
    dosbox_args.push(
      DosBoxLoader.mountImage("d", `/c/${options.img}`, "iso", "iso")
    );
  }

  const dosbox_loader = new DosBoxLoader(...dosbox_args);

  const canvas = dom;
  const emulator = new Emulator(canvas, null, dosbox_loader);
  canvas.height = 0;
  emulator.setSplashImage(require("@/assets/img/emularity_splash_image.svg"));
  emulator.start({ waitAfterDownloading: true });

  return emulator;
};
